/*ESTILOS GENERALES*/

/*COLORES*/

body {
  background-color: #fafbfc;
  color: #333333;
  position: relative;
  overflow-x: hidden;
}

html.freeze,
body.freeze {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.bg-white {
  background-color: #ffffff;
}

.text-success,
.alert-success i {
  color: #2e7d33 !important;
}

.bg-success,
.btn-success {
  background-color: #2e7d33 !important;
}
.alert-success {
  border-color: #2e7d33;
}

.text-primary {
  color: #0072bb !important;
}

.bg-primary,
.btn-primary {
  background-color: #0072bb !important;
}
.alert-primary {
  border-color: #0072bb;
}

a .fa-map-marker {
  color: #0072bb;
}
a {
  color: #0072bb;
}

a:hover {
  color: #6a1b99;
}

.underline,
.underline:hover {
  text-decoration: underline;
}

.text-info,
.alert-primary i {
  color: #039be5 !important;
}

.bg-info,
.btn-info {
  background-color: #039be5 !important;
}
.alert-info {
  border-color: #039be5;
}

.text-warning,
.alert-warning i {
  color: #f9a822 !important;
}

.bg-warning,
.btn-warning {
  background-color: #f9a822 !important;
}

.alert-warning {
  border-color: #f9a822;
}

.bg-gray {
  background-color: #eeeeee !important;
}

.bg-darkgray {
  background-color: #e0e0e0 !important;
}

.gray-bar {
  background-color: #606060 !important;
}

.btn {
  font-family: robotoMedium;
  font-size: 16px;
}

/* .mr15 {
  margin-right: 15px;
} */

.text-white {
  color: white;
}

/*.panel-body {*/
/*	padding-right:15px;
	padding-left:15px;
}*/

.productos-seleccionados {
  margin-left: 5px;
  /*opacity: 0.6;*/
}

.rem4 {
  margin-top: 4.1rem !important;
}

@media (max-width: 768px) {
  .productos-seleccionados {
    margin-left: 0rem;
  }
  .footer-buttons {
    padding-top: 0 !important;
  }
  .container > .navbar-header {
    margin-right: 0;
    margin-left: 20px;
  }
}

/*FONTS*/

/*@font-face {
	font-family: robotoRegular;
	src: url(fonts/Roboto-Regular.ttf);
}*/

@font-face {
  font-family: "robotoRegular";
  src: url("./fonts/Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoMedium";
  src: url("./fonts/Roboto-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoLight";
  src: url("./fonts/Roboto-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoBold";
  src: url("./fonts/Roboto-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "robotoBlack";
  src: url("./fonts/Roboto-Black.ttf");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: robotoRegular !important;
}

/*h1, h2, h3, h4, h5 {
	font-family: robotoMedium !important;
}
*/
.inline {
  display: inline-block !important;
}

.under {
  text-decoration: underline !important;
}

.font-bold {
  font-family: robotoBold !important;
}

.spinner {
  margin-left: 42%;
}

.table {
  margin-bottom: 0;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: middle;
}

.table > tbody > tr > td:first-of-type {
  line-height: normal;
}

.table-wrapper {
  margin: 0 5px;
}

.table-ppal {
  background-color: #eeeeee !important;
  border-collapse: separate;
  /* border-spacing: 0 5px;  */
  margin-top: -5px;
}

.table-ppal .height-tr {
  height: 3px;
}

.selected-border {
  /*border-left: solid 6px #0072BB;*/
  box-shadow: 6px 0 0 #0072bb inset !important;
}

/* .table-ppal .product-wrapper[data-length="0"] td *:not(.btn-icon-link) {
	color: #999 !important;
	text-decoration: line-through;
} */

.table-ppal .product-wrapper > td {
  border: solid 1px #e0e0e0;
  border-style: solid none;
  padding: 10px;
  background: #fff;
}
.table-ppal .product-wrapper > td:first-child {
  /* border-left-style: solid; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-ppal .product-wrapper > td:last-child {
  /* border-right-style: solid; */
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-left: solid 1px #e0e0e0;
}

/* CUANDO SE DESPLIEGA */
body.freeze {
  overflow: hidden;
}
.scrolling {
  overflow: hidden;
  overflow-y: auto;
}

.table-ppal .product-wrapper > td.erase-border-bottom:first-child {
  border-bottom-left-radius: 0;
}

.table-ppal .product-wrapper > td.erase-border-bottom:last-child {
  border-bottom-right-radius: 0;
}

.table-ppal > tbody > tr > td,
.table-ppal > thead > tr > th {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.table-ppal > tbody > tr > td:nth-child(2) {
  padding-right: 15px !important;
  padding-left: 0 !important;
}

.table-ppal > thead > tr > th:nth-child(2) {
  padding-right: 25px !important;
}

a.producto-unidades {
  font-size: 11px;
  text-decoration: underline;
  margin: 0 10px;
  display: inline-block;
}

.pr25 {
  width: 135px;
}

.panel-default {
  border: 1px solid #dfdfdf !important;
  box-shadow: none !important;
}

.jumbotron {
  background-image: url("../img/Jumbotron.jpg");

  /* background: rgba(0, 0, 0, 0.2);*/
  /*background-size: cover;*/
}

.jumbotron h1 {
  text-shadow: none !important;
}

.jumbotron-bajada {
  color: white;
  font-weight: 500 !important;
  text-shadow: none !important;
}

.jumbotron-overlay {
  background: rgba(0, 0, 0, 0.6);
}

.bread-jumbotron li a {
  color: white !important;
}

.breadcrumb li a {
  cursor: pointer !important;
}

.breadcrumb li label {
  color: white !important;
}

.bread-main {
  background-color: #606060;
}

.bread-main li a {
  color: white !important;
}

.breadcrumb > li + li:before,
.breadcrumb > .active {
  color: white !important;
}

.panel-default .panel-heading {
  min-height: auto !important;
  background-color: #ffffff;
  border-bottom: 1px solid #dfdfdf !important;
}

.promocard hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.panel-precios {
  box-shadow: 0 3px 8px -3px rgba(0, 0, 0, 0.3);
  margin: 0;
  font-size: 0.8rem;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.panel-precios.productos {
  margin: 30px 0;
  padding: 20px 0;
  position: static;
}

.panel-precios.productos .text-gris-claro {
  padding: 0 20px;
}
.step-3 .text-gris-claro {
  padding-top: 30px;
}

@media (max-width: 680px) {
  .panel-precios {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }
  .panel-precios .panel-body {
    padding-right: 15px;
    padding-left: 15px;
  }
  .panel-precios.productos {
    margin: 30px 12px;
  }
  .panel-precios {
    margin: 0 11px;
  }
}

@media (max-width: 360px) {
  .panel-precios.productos {
    margin-top: 20px;
    /*box-shadow: none;
		margin: 0;*/
    font-size: 0.8rem;
  }

  .lista-compras.sticky-display .header-carrito {
    width: 100%;
  }
}

.btn-collapse i {
  font-size: 1.2em;
}

.bold {
  font-weight: 500;
}

.bold-gris {
  /*font-weight: 600;*/
  color: #444;
  font-family: robotoBold !important;
}

.text-gris {
  color: #444;
}

.text-gris-claro {
  color: #606060;
}

.text-pasos {
  font-size: 14px;
  display: none;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.p-precios {
  padding: 15px 20px;
}

.btn-stripped {
  color: #0072bb;
  border: none;
  background-color: #fafbfc;
  margin-top: 30px;
  margin-bottom: 50px;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.btn-stripped:focus {
  outline: none;
}

.btn-gris {
  background-color: #e9e9e9 !important;
  color: #444 !important;
  border: 1px solid #dcdcdc !important;
}

.btn-ordenar {
  border: 1px solid #cccccc !important;
  font-weight: 400 !important;
}

.btn-ordenar span {
  float: right;
  margin-top: 5%;
}

.precios-header {
  padding: 0 20px;
}
.precios-title .fa {
  font-size: 1.5em !important;
  color: #2e7d33;
}

.precios-title {
  font-weight: 600 !important;
  font-size: 30px !important;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}

.precios-bajada {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 20px !important;
}

.step-3 .precios-title {
  padding-bottom: 15px;
}
.step-3 .precios-title i {
  vertical-align: -5px;
}
.step-3 .precios-bajada {
  font-size: 18px;
  line-height: 28px;
}
.actualizacion {
  color: #606060;
  padding: 10px 0 20px;
  font-weight: bold;
}

/* 
	Web fonts no aplican al dropdown en lagunos navegaodres
	Utilizar sans serif system safe.
*/
select option {
  font-family: "Helvetica neue", Helvetica, Arial, sans-serif;
}

/*.panel-precios label {
  font-weight:600;
}*/

.precios-label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px !important;
}

a:hover .precios-label,
a:visited .precios-label,
a:active .precios-label,
a:focus .precios-label {
  color: #333333;
}

.logo-pcuidados {
  width: 5.6em;
  padding-left: 0.8em;
}

.logo-pesenciales {
  width: 5em;
  padding-left: 0.8em;
}

.gps-wrapper {
  padding-top: 10px;
}

.gps-wrapper .fa-map-marker {
  font-size: 1.6em;
}

.gps-text {
  /*position:absolute;
  top:14px;*/
  font-weight: 400;
  font-size: 14px;
  padding-right: 5px;
}

.gps-text .fa-toggle-on {
  font-weight: 600;
  font-size: 24px;
  color: #039be5;
}

.gps-text .fa-toggle-on {
  font-weight: 600;
  font-size: 24px;
  color: #039be5;
}

.prefooter {
  background-color: #e9e9e9;
}
.prefooter a.panel {
  cursor: pointer;
}

.prefooter-txt {
  font-size: 16px;
}

.prefooter-bajada {
  font-weight: 600;
  font-size: 19.6px;
  padding-bottom: 0;
}

.prefooter .panel {
  height: 128px;
}

/*.panel-body.prefooter-bajada{
  padding: 25px 20px;
}*/

/*HEADER*/

header a.navbar-brand {
  overflow: visible;
}

html .row,
html .form-horizontal .form-group {
  margin: 0;
}
.footer-buttons {
  margin-top: 10px;
}
html .step3 label,
html .step4 label {
  font-size: 20px;
  text-align: left;
}
html .form-horizontal .control-label {
  text-align: left;
}
.review .txt {
  font-size: 20px;
  text-align: left;
  margin: 0;
  padding: 0;
}
html body .saving {
  background-color: #2e7d33;
  width: 90%;
  padding: 5px;
  font-size: 16px;
}

.eg-jump-lnk {
  margin-top: 50px;
  font-style: italic;
}
.lib-version {
  font-size: 12px;
  background-color: rgba(255, 255, 0, 0.38);
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
}

/*STEPZILLA*/

/* overwrite the stepzilla styles */
/*html ol.progtrckr li.progtrckr-todo:before {
	background-color: #e9e9e9;
}*/

ol.progtrckr {
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0px 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border: 0;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  color: #111;
  display: block;
  text-decoration: none;
  list-style-type: none;
  /*display:none;*/
}

/*.progtrckr li:nth-child(1),
.progtrckr li:nth-child(2) {
	width: calc(50% - 14px);
}
*/
ol.progtrckr li {
  display: inline-block;
  margin: 20px 0;
  height: 3px;
  line-height: 3px;
  width: 50%;
  border: 0;
  background: #e9e9e9;
  position: relative;
}

/*linea*/
ol.progtrckr li span {
  padding: 0 4rem;
}

/*Lineas de progreso color*/

/*A hacer*/
ol.progtrckr li.progtrckr-todo {
  color: #e9e9e9;
  /*border-bottom: 4px solid #e9e9e9;
	padding-bottom: 2px;*/
}

/*Haciendo*/
ol.progtrckr li.progtrckr-doing {
  color: white;
  /*border-bottom: 4px solid #e9e9e9;*/
  /*padding-bottom: 2px;*/
}

/*Hecho*/
ol.progtrckr li.progtrckr-done {
  color: white;
  background: #2e7d33;
  /*border-bottom: 4px solid #2e7d33;
	padding-bottom: 2px;*/
}

/*ol.progtrckr li:after {
  content: '';
  background-color: #E9E9E9;
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
}*/

ol.progtrckr li:before {
  position: relative;
  left: -12px;
  top: -12px;
  /*float: left;*/
  content: "";
  display: block;
  width: 28px;
  height: 28px;
  line-height: 24px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #e9e9e9;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  border: 2px solid #fff;
  color: #000;
  float: left;
}

/* ol.progtrckr li.progtrckr-todo:before {
	color: black;
	font-size: 13px;
	font-weight: 500;
	border: 2px solid white;
} */

ol.progtrckr li.progtrckr-doing:before {
  background-color: #0072bb;
  color: white;
  font-size: 14px;
}

ol.progtrckr li.progtrckr-done:before {
  background-color: #2e7d33;
  color: white;
  /*font-size: 13px;
	font-weight: 500;
	border: 2px solid white;*/
}

/*primer elemento*/
/*ol.progtrckr li:nth-child(1):before {
	position: relative;
	bottom: -1.2rem;
	float: left;
	left: 0%;
}*/

/*segundo elemento*/
/*ol.progtrckr li:nth-child(2):before {
	position: relative;
	bottom: -1.2rem;
	float: left;
	left: 0%;
}*/

/*tercer elemento*/
/*ol.progtrckr li:nth-child(3):before {
	position: relative;
	bottom: -1.2rem;
	float: left;
	left: 0%;
}*/

/*CIRCULO DOING POSICION*/
/*ol.progtrckr li.progtrckr-doing:before {
	position: relative;
	bottom: -1rem;
	float: left;
	left: 0%;
}*/

/*Borro la última línea del li */

ol.progtrckr li:nth-child(3) {
  width: 0;
}
ol.progtrckr li:nth-child(3):before {
  left: -18px;
}

.progtrckr em {
  display: none;
  /*font-weight: 700;
	padding-left: 1rem;*/
}

/*Numeros*/

ol.progtrckr li:nth-child(1).progtrckr-todo:before {
  content: "1";
}
ol.progtrckr li:nth-child(2).progtrckr-todo:before {
  content: "2";
}
ol.progtrckr li:nth-child(3).progtrckr-todo:before {
  content: "3";
}

ol.progtrckr li:nth-child(1).progtrckr-doing:before {
  content: "1";
}
ol.progtrckr li:nth-child(2).progtrckr-doing:before {
  content: "2";
}
ol.progtrckr li:nth-child(3).progtrckr-doing:before {
  content: "3";
}

ol.progtrckr li:nth-child(1).progtrckr-done:before {
  content: "1";
}
ol.progtrckr li:nth-child(2).progtrckr-done:before {
  content: "2";
}
ol.progtrckr li:nth-child(3).progtrckr-done:before {
  content: "3";
}

/*PRODUCTOS*/
/* 
.btn-product-wrapper:nth-of-type(odd) {
	padding-left: 0;
}
.btn-product-wrapper:nth-of-type(even) {
	padding-right: 0;
} */

.btn-product-wrapper {
  height: 0;
  line-height: 35px;
  margin: 0;
  overflow: hidden;
  width: 100%;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
}

.btn-product-wrapper.show {
  height: 35px;
  margin: 0 0 5px 0;
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
}

.btn-product-wrapper label {
  font-family: robotoRegular;
}

.btn-producto {
  background-color: white;
  border: solid 1px #ccc !important;
  /*color: #000;*/
  font-size: 15px !important;
  text-transform: none !important;
  text-align: left !important;
  font-weight: 400 !important;
  padding-left: 1.1em !important;
  margin-bottom: 15px !important;
  transition: all 0.2s ease;
}

.btn-producto.bg-primary:hover {
  color: white;
}

.btn-producto.bg-primary {
  font-family: robotoMedium;
}

.btn-producto .fa-times {
  padding-top: 2px;
}

.btn-producto:hover {
  background: #0072bb;
  color: #fff;
  border-radius: 4px;
}

@media (max-width: 650px) {
  .btn-producto .fa-times {
    padding-top: 3px;
  }
}

.link {
  text-decoration: underline;
}

.input-producto {
  visibility: hidden;
}

.modal-header {
  /*border-bottom: 0;*/
  padding: 15px 28px;
}
.modal-body {
  padding-top: 0;
}
.modal-footer {
  padding: 15px 28px;
}
.modal-total {
  font-size: 20px;
  padding: 15px 1.5rem 0;
}

/* .alert-warning > div{
  width: 80%;
} */

/*RANKING*/

.sucursalviewoptions {
  text-align: right;
  margin-bottom: 15px;
}
.sucursalviewoptions-label {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
}
.sucursalviewoptions .btn {
  background-color: #fff !important;
  padding: 6px 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}
.sucursalviewoptions .open > .dropdown-toggle.btn-default {
  color: #000 !important;
}
.sucursalviewoptions button {
  margin-bottom: 0;
  min-width: 170px;
}
.panel-supermercadocard {
  border: 1px solid #cccccc !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2) !important;
}

.panel-supermercadocard.panel-supermercadocard-elegida {
  border: 2px solid #0072bb !important;
  border-top-width: 6px !important;
}

.panel-supermercadocard .panel-body {
  padding-bottom: 10px;
  font-size: 14px !important;
}
.panel-supermercadocard .panel-body.top-separator {
  border-top: 1px solid #ccc;
}
.panel-supermercadocard .panel-body.top-separator-double {
  border-top: 2px solid #ccc;
}
.panel-supermercadocard .panel-body.table {
  padding: 0;
  background: #eeeeee;
}
/* .panel-supermercadocard .panel-body:last-child {
	padding-bottom: 15px;
} */

.panel-supermercadocard .supermercado-title {
  /* text-transform: uppercase; */
  /*font-weight: 600 !important;*/
  font-size: 1.7em;
}

.panel-supermercadocard .cantidad-productos {
  font-size: 16px;
}

.panel-supermercadocard .cantidad-productos strong {
  font-family: robotoBlack !important;
}

.panel-supermercadocard .link {
  display: block;
  font-size: 14px;
  text-align: center;
  border: 0;
  background-color: transparent;
  text-decoration: none;
}
.panel-supermercadocard .link.map {
  width: 70px;
  font-size: 12px;
  padding-top: 10px;
  color: #0072bb;
  cursor: pointer;
}
.panel-supermercadocard .link.map .fa {
  font-size: 28px;
}
.supermercado-iconos {
  float: right;
  width: 140px;
}

.panel-supermercadocard .btn {
  text-transform: none;
  font-weight: 500 !important;
}

.panel-supermercadocard .col {
  padding-left: 0;
  padding-right: 0;
}

.panel-supermercadocard .disponibles {
  font-size: 16px;
}
.panel-supermercadocard .ocultarproductos {
  padding: 10px;
  font-size: 13px;
}
.panel-supermercadocard .precio-total {
  font-size: 38px;
  /*font-weight: 600 !important;*/
}
.panel-supermercadocard .precio-total i {
  font-size: 32px;
  color: #f9a822;
  padding-right: 20px;
}

.panel-supermercadocard .warning-precio-producto {
  font-size: 1.3em;
  color: #f9a822;
  vertical-align: -3px;
  /*padding-right: 12px;*/
}

td.precio-wrapper {
  position: relative;
  text-align: center;
}

a.producto-unidades {
  font-size: 11px;
  text-decoration: underline;
  /*margin:0 10px;*/
  display: block;
}

.panel-supermercadocard .table-responsive table {
  margin-bottom: 0;
}
.panel-supermercadocard .table-heading tr th {
  font-size: 12px;
  text-transform: uppercase;
  vertical-align: middle;
}
.panel-supermercadocard .table-responsive td {
  vertical-align: middle;
}
.panel-supermercadocard .table-responsive th:first-child,
.panel-supermercadocard .table-responsive td:first-child {
  padding-left: 20px;
}
.panel-supermercadocard .table-responsive th:last-child,
.panel-supermercadocard .table-responsive td:last-child {
  padding-right: 20px;
}

.panel-supermercadocard .elegirproducto-content {
  background-color: #f8f8f8;
  border-top: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.panel-supermercadocard .elegirproducto-content > div {
  padding: 20px 0;
}
.panel-supermercadocard .elegirproducto-content .table {
  background-color: transparent;
}

.product-wrapper .elegir-producto button {
  background-color: #2e7d33;
  color: #fff;
  border-radius: 4px;
}

.panel-supermercadocard .elegirproducto-content .product-selected {
  background-color: #0072bb;
  color: #fff !important;
  font-family: robotoBold;
}

tr.product-selected td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
tr.product-selected td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

tr.elegir-producto-wrapper .elegirproducto-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.product-disable {
  color: #606060;
}
.product-disable small {
  color: #606060 !important;
}

.producto-eliminado,
.producto-eliminado small,
.producto-eliminado .text-gris,
.producto-eliminado .bold-gris,
.producto-eliminado i,
.producto-eliminado a {
  color: #cccccc !important;
  text-decoration: line-through;
}
table.producto-eliminado {
  text-decoration: none !important;
}

.warning-alert {
  font-size: 52px;
  color: #f9a822;
  padding: 10px 25px 10px 10px;
}

.btn-icon-link {
  border: 0;
  background: transparent;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  opacity: 0.4;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-content: center;
}

.ranking-link {
  text-decoration-line: underline;
  cursor: pointer;
}

/*PROMOCIONES*/

.row-eq-height .panel {
  height: 340px;
}

@media (max-width: 650px) {
  /*.text-gris-claro {display:block;}
	.progtrckr {display: none;}*/

  .supermercado-iconos {
    width: 70px;
  }
  /*ol.progtrckr li:nth-child(1):before {
		position: relative;
		bottom: -1rem;
		float: left;
		left: 0%;
	}

	
	ol.progtrckr li:nth-child(2):before {
		position: relative;
		bottom: -1rem;
		float: left;
		left: 0%;
	}

	
	ol.progtrckr li:nth-child(3):before {
		position: relative;
		bottom: -0.7rem;
		float: left;
		left: 0%;
	}

	ol.progtrckr li.progtrckr-doing:nth-child(3):before {
		position: relative;
		bottom: -0.7rem;
		float: left;
		left: 0%;
	}*/

  /* .alert-warning > div{
	    width: 70%;
	  } */

  /*

	ol.progtrckr li.progtrckr-doing:before {
	  position: relative;
	  bottom: -1rem;
	  float: left;
	  left: 0%;
	}


	ol.progtrckr li.progtrckr-todo:before {
	  position: relative;
	  bottom: -0.6rem;
	  float: left;
	  left: 0%;

	}*/
}

@media (max-width: 650px) {
  .progtrckr em {
    display: none;
  }

  /*.producto-nombre {
		font-size: 15px;
	}*/

  .pr25 {
    width: 115px;
  }

  .jumbotron-overlay {
    display: none;
  }

  .bread-main {
    display: none;
  }
}

/* .prefooter {
	margin-top:90px!important;
} */

/*FOOTER*/

.main-footer {
  border: 1px solid #cccccc;
}

.main-footer .image-responsive {
  max-width: 250px !important;
}

/*FOOTER BUTTONS*/

/*Imito panel*/
/* .footer-buttons { */
/*margin-bottom: 80px;*/
/* background-color: #fff;
	box-shadow: 0 3px 10px -3px rgba(0,0,0,0.3);
	border: 0;
	border-radius: 4px;
	border-top-left-radius: 0;
	border-top-right-radius: 0; */
/* border: 1px solid #dfdfdf !important; */
/* border-top: none !important; */
/* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2); */
/* color: #111;
	display: block;
	text-decoration: none !important;
	padding-top: 45px !important;
	padding-bottom: 45px !important;
	padding-right: 20px;
	padding-left: 20px; */
/*height: 100px;*/
/* margin-top: 0px;
	margin-left: 20px;
	margin-right: 20px;
	position: relative;
	text-align: center; */
/* } */

/* .footer-buttons:before {
	content: "";
	height: 7px;
	width: 98%;
	position: absolute;
	top: -7px;
	right:0px;
	background: inherit;
	z-index: 2;
  } */

/*.footer-buttons .btn-link {
	border: solid #9e9e9e 1px;
	color: #606060;
	background-color: #eeeeee;
}
*/
@media (max-width: 768px) {
  .productos-seleccionados {
    margin-left: 0rem;
  }
  /*.footer-buttons {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
	}*/
  /*	.footer-buttons .btn.btn-link {
		border: none;
		color: #0072bb;
		background-color: #fff !important;
		cursor: pointer;
	}
	.modal-buttons .btn.btn-link {
		border: none;
		color: #0072bb;
		background-color: #fafbfc !important;
		cursor: pointer;
	}*/
}

.footer-buttons .btn {
  padding: 12px 25px;
  font-family: robotoMedium;
}

/*Ubicacion*/
.buttons-ubicacion .btn {
  padding: 16px 25px;
  font-family: robotoMedium;
}

/*Modal-ubicacion*/
.modal-btn-wrapper {
  text-align: center !important;
  border-top: none;
  padding-top: 0;
  padding-bottom: 10px;
}

.modal-btn-wrapper .btn {
  padding: 16px 25px;
  margin: 20px;
  font-family: robotoMedium;
}

.footer-buttons .btn-gris {
  width: 144px !important;
  margin-right: 15px;
}
.footer-buttons .btn-success {
  width: 144px !important;
  margin-left: 15px;
}

@media (max-width: 1090px) {
  .btn-product-wrapper:nth-of-type(odd) {
    padding: 0;
  }
  .btn-product-wrapper:nth-of-type(even) {
    padding: 0;
  }

  .footer-buttons .btn {
    width: auto !important;
    margin-right: 0;
  }

  .modal-btn-wrapper .btn {
    padding: 16px 25px;
    margin: 15px;
    font-size: 14px;
  }

  .buttons-ubicacion .btn {
    padding: 16px 25px;
    margin: 15px;
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .text-gris-claro {
    display: block;
  }
  ol.progtrckr {
    display: none;
  }

  .modal.in .modal-dialog {
    margin: 15px 15px;
  }

  .panel-supermercadocard .link.map .fa {
    font-size: 24px;
  }
  .panel-supermercadocard .link.map span {
    display: none;
  }
  .panel-supermercadocard .link.map,
  .supermercado-iconos {
    width: 30px;
  }
  .panel-supermercadocard .link.map {
    margin-bottom: 5px;
  }
  .seleccionar-sucursal .btn {
    background: #fff !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    border: 2px solid #0072bb;
    border-radius: 4px;
    opacity: 1;
  }
  .seleccionar-sucursal .btn:hover {
    background: #0072bb !important;
    color: #fff;
  }
  .panel-default.panel-producto .operador {
    padding: 10px 0;
  }
}

@media (min-width: 560px) {
  .text-alert {
    padding-top: 1em;
  }
}

@media (max-width: 560px) {
  .footer-buttons .btn,
  .buttons-ubicacion .btn {
    width: auto !important;
    margin: 5px;
    padding: 12px 25px;
    font-size: 14px;
  }
  /* .alert-warning > div{
  width: 100%;
} */
}

.step1 {
  left: -2%;
  z-index: 1;
}

.step2 {
  left: 37%;
}

.step3 {
  left: 37%;
}

@media (max-width: 560px) {
  .warning-alert {
    font-size: 2em;
    padding: 10px 25px 10px 0;
  }

  .step1 {
    left: -4%;
    z-index: 1;
  }

  .step2 {
    left: 30%;
  }

  .step3 {
    left: 37%;
  }

  .table-ppal > tbody > tr > td,
  .table-ppal > thead > tr > th {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .table-ppal > tbody > tr > td:first-of-type,
  .table-ppal > thead > tr > th:first-of-type {
    padding-left: 15px !important;
    line-height: normal;
  }

  .panel-supermercadocard .elegirproducto-content {
    padding-right: 10px !important;
  }

  .table-ppal > tbody > tr > td:nth-child(2) {
    padding-right: 10px !important;
  }

  .table-ppal > thead > tr > th:nth-child(2) {
    padding-right: 25px !important;
  }
}

@media (max-width: 768px) {
  .gps-wrapper .fa-map-marker {
    float: left !important;
    text-align: left !important;
    /*padding-bottom:10px;*/
  }

  .row-eq-height .panel {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .align-desktop {
    float: right;
    text-align: right;
  }

  .right-desktop {
    float: right !important;
  }

  .modal-content {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  }

  .modal-dialog {
    width: 450px;
  }
}

/*FIXES EN FIREFOX*/
/*
@-moz-document url-prefix() {
	ol.progtrckr li.progtrckr-doing::before {
		bottom: 0 !important;
	}

	@media (min-width: 768px) {
		/*ol.progtrckr li:nth-child(3)::before {
			bottom: -11px !important;
		}

		ol.progtrckr li::before {
			bottom: 3px !important;
		}

		li.progtrckr-todo::before {
			bottom: 3px !important;
		}

		ol.progtrckr li:nth-child(3)::before {
			bottom: -20px !important;
		}

		ol.progtrckr li {
			height: 10px;
		}

		ol.progtrckr li.progtrckr-doing {
			bottom: 0 !important;
		}

		ol.progtrckr li.progtrckr-doing::before{
   		 bottom:7px!important;
  		}
	}

	@media (max-width: 768px) {
		ol.progtrckr li:nth-child(3)::before {
			bottom: -11px !important;
		}
	}
}
*/

.step-wrapper {
  position: relative;
}

.pop-step-1,
.pop-step-2,
.pop-step-3 {
  background-color: transparent !important;
  z-index: 5;
  padding: 20px 20px;
  border-radius: 50%;
  outline: none;
  cursor: inherit;
}

.pop-step-1:focus,
.pop-step-2:focus,
.pop-step-3:focus {
  outline: none !important;
}

.pop-step-1:active,
.pop-step-2:active,
.pop-step-3:active {
  box-shadow: none !important;
  border: none !important;
}

.pop-step-1 {
  position: absolute;
  top: 0;
  left: 15px;
}

.pop-step-2 {
  position: absolute;
  top: 0;
  left: 47%;
}

.pop-step-3 {
  position: absolute;
  top: 0;
  right: 15px;
}

/*PROCESO*/

.connecting-line {
  height: 1px;
  background: #9e9e9e;
  position: absolute;
  width: 60%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 24%;
  z-index: 0;
}

.proceso {
  text-align: center;
}

.proceso p {
  padding-top: 15px;
  padding-bottom: 20px;
}

.proceso-circle {
  height: 155px;
  width: 155px;
  border-radius: 50%;
  color: white;
  background-color: #9e9e9e;
  border: 35px solid #eeeeee;
}

.proceso-circle .numero {
  font-size: 2.5rem !important;
  padding: 16px;
  padding-bottom: 0;
}

@media (max-width: 1200px) {
  .connecting-line {
    display: none !important;
  }

  .proceso p {
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .proceso-circle {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    color: white;
    background-color: #9e9e9e;
    border: 25px solid #eeeeee;
  }

  .proceso-circle .numero {
    font-size: 1.5rem !important;
    padding: 10px;
    padding-bottom: 0;
  }

  .proceso p.medium {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.panel-accordion {
  border: none !important;
  margin-top: 5px;
}

@media (max-width: 640px) {
  .panel-accordion > .otro,
  .panel-accordion > .disabled {
    padding: 0 6px;
  }
}

/*RECAPTCHA*/

.g-recaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 492px) {
  /*.panel-supermercadocard .warning-precio-producto {
		padding-right: 30px;
	}*/
}

@media (max-width: 390px) {
  .g-recaptcha iframe {
    transform: scale(0.8);
  }
  .auto-input .react-autosuggest__input {
    width: 100%;
  }
}

@media (max-width: 320px) {
  /*.panel-supermercadocard .warning-precio-producto {
		padding-right: 25px;
	}*/
}

#lista--body {
  padding: 0 20px 20px;
}

.lista-compras {
  padding: 1rem 0;
  margin-top: 30px;
  background-color: #eeeeee;
}
.lista-compras p {
  padding-bottom: 1em;
  font-size: 14px;
}
.lista-compras hr {
  margin: 7px 0 12px 0;
  padding: 0;
}

.lista-compras ul {
  padding: 0;
}
.lista-compras li {
  list-style-type: none;
  font-family: robotoRegular;
  font-size: 16px;

  cursor: pointer;
}

.lista-compras > h4 {
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  padding: 0 0 5px;
  margin: 30px 0 10px;
}
/* .lista-compras li i { */
/* opacity: .5; */
/*padding: 0 1rem;*/
/* } */

@media (max-width: 768px) {
  .lista-compras li .fa-times {
    padding-right: 0;
  }
  /* .producto-nombre {
		padding-left: 3px !important;
	} */
  .table-ppal .producto-nombre {
    padding-left: 0 !important;
  }
}

.panel-collapse .panel-body {
  margin-bottom: 1.5rem;
}

.nav-footer {
  padding: 10px 0;
  margin-top: 40px;
  border-top: 1px solid #cbcbcb;
}

/*MODAL*/

.modal-buttons .btn {
  min-width: 150px !important;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}

.modal-buttons .btn-link {
  border: solid #9e9e9e 1px;
  color: #606060;
  background-color: #eeeeee;
}

/*ALERT*/

.alert {
  padding-top: 10px;
  padding-bottom: 25px;
  border-width: 2px;
}

.alert .fa {
  padding-top: 30px;
}

.alert .col-xs-10 {
  padding-top: 15px;
}

/*MEDIAQUERY*/

@media (max-width: 492px) {
  .modal-buttons .btn,
  .footer-buttons .btn {
    width: 202px !important;
  }
  .lista-compras li i {
    padding-left: 0rem;
  }
  .alert {
    padding: 0;
  }
  .alert button.close {
    padding-top: 4px;
    padding-right: 10px;
    padding-bottom: 0;
  }

  .alert .col-xs-10 {
    padding-bottom: 24px;
    padding-top: 0;
  }

  .alert .fa {
    font-size: 40px;
    padding-right: 10px;
    padding-top: 36px;
    padding-bottom: 0;
  }

  .jumbotron .jumbotron_body {
    padding: 55px 0;
  }
  .paso-a-paso {
    padding-top: 1rem !important;
  }
  .btn-collapse {
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 5px;
  }
  .btn-collapse i {
    font-size: 1em !important;
  }

  .precios-title {
    font-size: 30px;
  }
  .precios-bajada {
    font-size: 18px;
  }
}

/*HEADER*/

.navbar-default .nav > li > a {
  color: #0072bb;
  font-size: 18px;
}

.txt20 {
  font-size: 1.25rem;
  line-height: 1.22857143;
}

.txt18 {
  font-size: 1.125rem;
}

/*PREFOOTER*/

.tepuedeinteresar li {
  padding-bottom: 10px;
}
.tepuedeinteresar li a {
  font-family: robotoBold;
}
.tepuedeinteresar h5 {
  margin-bottom: 20px;
  font-family: robotoBold;
}

.geo-txt {
  font-size: 24px;
  line-height: 36px;
}

.elegir-producto {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.panel-faq .panel-collapse p,
.panel-faq .panel-collapse li {
  font-size: 16px;
}

.panel-faq {
  padding-bottom: 80px !important;
}

.p-y-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.panel-faq .titulo-productos {
  font-weight: 600 !important;
  padding-bottom: 25px;
  padding-top: 25px;
}

.header-sobre a:hover,
.navbar-default .navbar-nav > li > a:hover {
  color: white !important;
}

.header-sobre > a:hover {
  color: white !important;
}

.paso-a-paso {
  padding-top: 3rem;
}

.producto-nombre {
  padding-left: 0;
}
.panel-supermercadocard .panel-body .producto-nombre {
  font-size: 16px;
}

.btn-fa {
  font-size: 1em !important;
  margin-left: 0 !important;
}

@media screen and (max-width: 767px) {
  .navbar .nav > li > a {
    padding-left: 35px;
  }
}

.to-buttons {
  border-radius: 50%;
  position: fixed;
  top: 85%;
  right: 2%;
  z-index: 5;
  padding: 15px 17px;
  background-color: #dddddd;
}

.to-buttons:focus {
  outline: 0 !important;
}

.bottom {
  height: 0;
}

@media screen and (min-width: 767px) {
  .to-buttons {
    display: none;
  }
}

.texto-sobre a {
  text-decoration: underline;
  font-size: 17px;
}

/* h1 {
	font-family: "robotoBold";
} */

.snackbar {
  visibility: visible;
  width: 100%;
  text-align: center;
  position: fixed;
  left: 0px;
  bottom: -60px;
  transition: all 0.45s ease-in-out;
  overflow: hidden;
  z-index: 100;
}
.snackbar.show {
  height: auto;
  bottom: 0px;
  z-index: 90;
}

.snackbar .snackbar-card {
  display: inline-block;
  background-color: #323232;
  border-radius: 4px 4px 0 0;
  color: #fff;
  padding: 16px 25px;
  z-index: 9;
}

.snackbar-card p {
  font-size: 16px;
  margin: 0;
}

.snackbar-card.sin-productos {
  border: 2px solid #0072bb;
  background-color: #daf1f8;
  font-size: 14px;
  color: #111;
  border-radius: 0px;
  padding: 10px 10px;
  width: 100%;
  line-height: 28px;
  text-align: center;
}

.snackbar-card.sin-productos i {
  font-size: 26px;
  margin: 0 5px 0 0;
  vertical-align: middle;
}

.show {
  bottom: 0px;
}

/*.show-over {
	bottom: 50px;
}*/

/* INICIO MOBILE */

.sticky {
  transition: all 0.5s ease;
}

.sticky .panel-accordion {
  background-color: #eeeeee;
}

.step-mobile {
  height: 5px;
  width: 30px;
  background-color: #9e9e9e;
  margin: auto 1px;
}

/* .sticky-display {
		height: 100vh;
		overflow: scroll;
	} */

/* .lista-compras {
		overflow: hidden;
	} */

.step-mobile.completed {
  /*background-color: #0072bb;*/
  background-color: #fff;
}

.step-mobile-wrapper {
  /*margin-top: 8px;*/
}

.mobile-right,
.mobile-left {
  color: #606060;
  font-weight: bold;
}

.mobile-left span,
.mobile-right span {
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}

.mobile-right span {
  /*margin-right: 3px;*/
}

.mobile-left span {
  /*margin-left: 3px;*/
}

.panel-producto {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
}

.panel-producto.panel-modal {
  height: 40px;
  opacity: 1;
}

.visible {
  height: 35px;
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.36, -0.64, 0.34, 1.76);
  margin: 0 0 8px;
}

.panel-producto .int-producto {
  display: block;
  padding: 7px 10px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin: 0;
}
.panel-producto .int-producto:hover,
.panel-producto .int-producto:hover button .fa {
  color: #fff !important;
  background-color: #0072bb;
}
.panel-producto .operador:hover,
.panel-producto .operador:hover .fa {
  color: #fff !important;
  background-color: #0072bb;
}

.panel-producto .operador,
.panel-producto .numero-productos,
.panel-producto button {
  float: right;
  display: block;
  padding: 0 7px;
  /* line-height:25px; */
}
.panel-default.panel-producto .operador {
  padding: 10px 0;
}

span.numero-productos {
  font-weight: bold;
  font-size: 15px;
}

.step-2 .producto-nombre {
  display: block;
  margin-right: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
}
.panel-producto button {
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 24px;
}
.panel-modal.panel-producto:last-of-type {
  margin-bottom: 5px;
}
.panel-producto .numero-modal {
  font-size: 15px;
  padding-top: 2px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

br[clear="all"] {
  height: 1px;
  overflow: hidden;
  line-height: 1px;
}

.panel-producto:last-of-type {
  margin-bottom: 20px;
}

.modal-cambio-cantidad .modal-content {
  padding-top: 10px;
  font-size: 14px;
}

.modal-cambio-cantidad .logo-pesenciales {
  width: 3em;
}

.modal-cambio-cantidad .modal-body {
  border-bottom: 1px solid #e0e0e0;
}

.modal-cambio-cantidad .btn {
  margin-top: 25px;
  padding: 10px 25px;
}

.modal-cambio-cantidad .btn-link {
  border: 1px solid #9e9e9e;
  color: #606060;
}

.operador {
  cursor: pointer !important;
}

.operador i {
  color: #0072bb !important;
  font-size: 16px;
  font-weight: bold;
}

.modal-cambio-cantidad .operador i {
  font-size: 20px;
}

.numero-productos {
  padding-top: 12px;
  padding-bottom: 12px;
}

.numero-productos span {
  font-size: 22px;
  font-weight: bold;
  line-height: 35px;
}

.seleccionar-sucursal .btn {
  position: relative;
  text-align: left;
  padding-left: 52px;
  text-transform: none;
  color: #0072bb;
  margin: auto;
  display: block;
  background: transparent;
}
.seleccionar-sucursal .btn .fa {
  position: absolute;
  top: 21px;
  left: 20px;
}

.no-scroll {
  overflow: hidden !important;
}

.sticky-bottom {
  /*padding-top: 15px;*/
  height: 50px;
  position: fixed;
  width: 100%;
  z-index: 101;
  bottom: -50px;
  left: 0px;
  background-color: #0072bb;
  color: #fff;
  transition-duration: 450ms;
  transition-timing-function: ease-in-out;
  border-top: solid 1px #9e9e9e;
}
.sticky-bottom.show {
  bottom: 0;
}
.sticky-bottom > div {
  height: 50px;
}
.sticky-bottom > div > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.sticky-bottom div,
.sticky-bottom span {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
}

.sticky-bottom-2 {
  /*padding-top: 10px;*/
}

.sticky-bottom-2 .mobile-left {
  /*padding-top: 5px;*/
}

/* .sticky-bottom-2 .mobile-right {
	padding-top:;
} */

.fa-angle-double-up {
  font-size: 30px;
  font-weight: bold;
  color: #606060;
}

.fa-angle-double-down {
  font-size: 30px;
  font-weight: bold;
  color: #606060;
}

.lista-compras .lista-title {
  font-size: 21px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-top: 8px;
  color: #0072bb;
}
.lista-title > i:first-child {
  font-size: 23px;
  color: #0072bb;
  margin: 0 3px 0 0;
}
.lista-compras .fa-file-text-o {
  margin-top: 6px;
  color: #606060;
}

.lista-title span.productos-seleccionados {
  font-size: 14px;
  color: #000;
}

.lista-title span.productos-seleccionados[length="0"] {
  color: #999;
  font-weight: normal;
}

.row.header-carrito {
  margin: 0;
}

@media (min-width: 768px) {
  .sticky-bottom {
    display: none !important;
  }
}

/* FIN MOBILE */
@media (max-width: 768px) {
  .main-footer {
    margin-bottom: 50px;
  }

  .lista-compras li i {
    opacity: 1;
  }

  .sticky.sticky-display .lista-title,
  .sticky.sticky-display .productos-seleccionados {
    color: white !important;
    opacity: 1;
  }

  .sticky.sticky-display .lista-title {
    padding-top: 0.8rem;
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #eeeeee;
    z-index: 5;
  }

  .sticky.sticky-display {
    height: calc(100% - 50px);
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 70px 0 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .panel-precios {
    border: none !important;
  }

  .sticky-display .search-wrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 80px;
  }

  .container.step-2,
  .step-wrapper {
    padding: 0px;
  }

  .container.step-3 {
    padding: 10px;
    /* padding-right: 1rem !important;
		padding-left: 1rem !important; */
  }
  .lista-compras {
    border: none;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .sticky-display .header-carrito {
    background: #0072bb;
    color: white;
    position: fixed;
    top: 0px;
    width: 100%; /* calc(100% - 37px) */
    z-index: 6;
    height: 65px;
    transition: all 0.8s ease-in-out;
  }
  .sticky-display .header-carrito .fa-file-text-o,
  .sticky-display .header-carrito .fa-caret-up {
    color: #fff;
  }

  .snackbar.show.over-sticky {
    bottom: 50px;
  }
}
.btn-bajo {
  padding: 12px 25px;
}

/*.panel-precios .panel-body {
	padding: 0;
}*/

*[role="tooltip"] {
  font-size: 13px;
  padding: 0;
  height: 40px;
}

/* Desaparece stepper en paso 2 */

.step2-mobile .progtrckr,
.step2-mobile .footer-buttons {
  display: none;
}

.step3-mobile .progtrckr {
  display: none;
}
.step3-mobile .multi-step > div > div:first-child {
  padding: 0 20px;
}

/* AUTOCOMPLETE */

.autocomplete-container {
  display: flex;
  /*width: 100%;*/
  margin: 20px 20px 10px;
}

/*.autocomplete-container.outsider {width:auto;}*/
/*.sticky-display .autocomplete-container.outsider {display:none;}*/

.autocomplete-container button {
  font-size: 16px;
  width: 20%;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.auto-input {
  display: flex;
}

.auto-input input {
  background-color: white;
  border: 1px solid #9e9e9e;
  border-right: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: -3px;
}

.auto-addon {
  display: flex;
  background-color: white;
  border: 1px solid #9e9e9e;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.auto-addon span {
  display: flex;
  color: #9e9e9e;
  align-content: center;
  align-items: center;
  padding-right: 10px;
}

.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 38.5px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
  box-shadow: 0 3px 8px -3px rgba(0, 0, 0, 0.5);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.disabled .panel-body {
  margin: 0px;
}

.disabled p,
.disabled label {
  color: #9e9e9e !important;
}

.agregar-producto {
  border: 2px solid #0072bb;
  border-radius: 4px;
  padding: 14px;
  position: relative;
  cursor: pointer;
  line-height: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: background 0.3s ease;
}

.agregar-producto span {
  font-size: 13.5px;
  font-weight: 600;
  text-align: center;
  color: #0072bb;
}

.agregar-producto i,
.agregar-producto svg {
  float: left;
  font-size: 26px;
  color: #0072bb;
  margin: 0 5px 0 0;
}

.agregar-producto:hover {
  background: #0072bb;
  color: #fff;
}
.agregar-producto:hover span,
.agregar-producto:hover i,
.agregar-producto:hover svg {
  color: #fff;
}

/*  */
